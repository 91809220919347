import { productConstants } from "../constants";

export function product(state = {}, action) {
  switch (action.type) {
    case productConstants.LOADING_REQUEST:
      return {
        ...state,
        loding: action.isbool,
      };
    case productConstants.DIALOG_REQUEST:
      return {
        ...state,
        showDialog: action.isbool,
      };
    case productConstants.DIALOG_ISACTIVE_REQUEST:
      return {
        ...state,
        showIsActiveDialog: action.isbool,
      };
    case productConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productConstants.GETALL_SUCCESS:
      return {
        ...state,
        items: action.datas,
      };
    case productConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productConstants.GETALL_RATING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productConstants.GETALL_RATING_SUCCESS:
      return {
        ...state,
        ratings: action.datas,
      };
    case productConstants.GETALL_RATING_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productConstants.EXPORTALL_RATING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productConstants.EXPORTALL_RATING_SUCCESS:
      return {
        ...state,
        exportitems: action.datas,
      };
    case productConstants.EXPORTALL_RATING_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productConstants.CREATE_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case productConstants.CREATE_SUCCESS:
      return {
        ...state,
        items:
          state.items == null ? [action.data] : [action.data, ...state.items],
      };
    case productConstants.CREATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productConstants.UPDATE_REQUEST:
      return {
        ...state,
        items: state.items.map((item) =>
          item.ProductId == action.data.ProductId
            ? { ...item, updating: true }
            : item
        ),
      };
    case productConstants.UPDATE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.ProductId === action.data.ProductId ? action.data : item
        ),
      };
    case productConstants.UPDATE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productConstants.DELETE_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case productConstants.DELETE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.ProductId === action.data
            ? {
                ProductId: item.ProductId,
                CompanyId: item.CompanyId,
                CompanyName: item.CompanyName,
                ProductCode: item.ProductCode,
                ProductName: item.ProductName,
                ProductDescription: item.ProductDescription,
                PermissibleScan: item.PermissibleScan,
                GenuineMessage: item.GenuineMessage,
                AlreadyScannedMsg: item.AlreadyScannedMsg,
                ProductImage: item.ProductImage,
                GenuineAudio: item.GenuineAudio,
                AlreadyScannedAudio: item.AlreadyScannedAudio,
                ProductSpecification: item.ProductSpecification,
                IsActive: false,
                CreatedDate: item.CreatedDate,
                UpdatedDate: item.UpdatedDate,
              }
            : item
        ),
      };
    case productConstants.DELETE_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productConstants.GETPRDCTDDL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case productConstants.GETPRDCTDDL_SUCCESS:
      return {
        ...state,
        prdctddl: action.datas,
      };
    case productConstants.GETPRDCTDDL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case productConstants.ISACTIVE_REQUEST:
      return {
        ...state,
        activating: true,
      };
    case productConstants.ISACTIVE_SUCCESS:
      return {
        ...state,
        items: state.items.map((item) =>
          item.ProductId === action.data.ProductId
            ? {
                ProductId: item.ProductId,
                CompanyId: item.CompanyId,
                CompanyName: item.CompanyName,
                ProductCode: item.ProductCode,
                ProductName: item.ProductName,
                ProductDescription: item.ProductDescription,
                PermissibleScan: item.PermissibleScan,
                GenuineMessage: item.GenuineMessage,
                AlreadyScannedMsg: item.AlreadyScannedMsg,
                ProductImage: item.ProductImage,
                GenuineAudio: item.GenuineAudio,
                AlreadyScannedAudio: item.AlreadyScannedAudio,
                ProductSpecification: item.ProductSpecification,
                IsActive: action.data.IsActive,
                TotalRows: item.TotalRows,
                CreatedDate: item.CreatedDate,
                UpdatedDate: item.UpdatedDate,
              }
            : item
        ),
      };
    case productConstants.ISACTIVE_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}
