import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  Sphere,
} from "react-simple-maps";
import { render } from "react-dom";
import chroma from "chroma-js";
import world from "../assets/world.json";
import geoJson from "../assets/custom.geo.json";
//import geoJson from "../assets/india_state_geo.json";
import { Tooltip } from "@mui/material";

const getRandomInt = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);
const colorScale = chroma.brewer.Spectral.slice(1);

const colors = Array(600)
  .fill()
  .map((d) => colorScale[getRandomInt(0, colorScale.length - 1)]);

const MapChart = ({ handleClick }) => {
  return (
    <ComposableMap viewBox="-90 65 970 470">
      <Sphere stroke="#E4E5E6" strokeWidth={1} />
      <Graticule stroke="#E4E5E6" strokeWidth={1} />
      <Geographies geography={geoJson}>
        {({ geographies }) =>
          geographies.map((geo, i) => (
            <Tooltip
              key={geo.rsmKey}
              componentsProps={{
                tooltip: {
                  sx: {
                    color: "#FFFFFF",
                    backgroundColor: "#2a354d",
                    fontSize: "18px",
                    padding: "0.75rem",
                    borderBottomLeftRadius: "0.375rem",
                    borderBottomRightRadius: "0.375rem",
                    borderTopRightRadius: "0.375rem",
                    fontFamily:
                      "IBM Plex Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
                  },
                },
              }}
              title={`${geo.properties.name}`}
              followCursor
            >
              <Geography
                key={geo.rsmKey}
                // onMouseEnter={() => {
                //   setTooltipContent(`${geo.properties.name}`);
                // }}
                // onMouseLeave={() => {
                //   setTooltipContent("");
                // }}
                geography={geo}
                onClick={() => {
                  handleClick(geo);
                }}
                style={{
                  default: {
                    fill: colors[i],
                    outline: "none",
                  },
                  hover: {
                    fill: colors[i],
                    outline: "none",
                  },
                  pressed: {
                    outline: "none",
                  },
                }}
              />
            </Tooltip>
          ))
        }
      </Geographies>
    </ComposableMap>
  );
};

export default MapChart;
