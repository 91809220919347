import React, { Component, useState } from "react";
import { Chart } from "primereact/chart";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { dashboardActions, mappingActions } from "../actions";
import tableRequest from "../models/tableRequest.js";
import banner1 from "./../assets/images/pooni-rice.jpg";
import banner2 from "./../assets/images/basmati-rice.jpg";
import banner3 from "./../assets/images/boiled-rice.jpg";
import "./Dashboard.css";
import MapChart from "./MapChart";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.showMap = this.showMap.bind(this);
    this.state = {
      globalFilter: null,
      first: 0,
      rows: 5,
      totalRecords: 0,
      sortOrder: "",
      loading: false,
      showMap: true,
    };
  }

  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);

      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        }
      }
    }
    this.props.fetchApi(this.tblResponse);
    this.props.fetchMappingApi(this.tblResponse);
  }

  componentDidUpdate(prevProps) {
    if (this.props.MappingData) {
      if (prevProps.MappingData !== this.props.MappingData) {
        // this.props.myProp has a different value
        if (this.props.MappingData) {
          var e = this.props.MappingData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }

    if (prevProps.MaxFromNo !== this.props.MaxFromNo) {
      this.setState({
        FromNo: this.props.MaxFromNo,
      });
    }
  }

  getLazyLoadingValues(event) {
    debugger;
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchMappingApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchMappingApi(this.tblResponse);
  }

  showMap() {
    this.setState({ showMap: true });
  }

  onPage(event) {
    //imitate delay of a backend call
    this.getLazyLoadingValues(event);
    this.props.fetchMappingApi(this.tblResponse);
  }
  render() {
    const chartdata = {
      labels: this.props.Months,
      datasets: [
        {
          label: "Product Mapping",
          data: this.props.MappingChart,
          fill: false,
          borderColor: "#4bc0c0",
        },
        {
          label: "Product De-Mapping",
          data: this.props.DeMappingChart,
          fill: false,
          borderColor: "#9bc0c9",
        },
      ],
    };

    return (
      <>
        <div hidden={this.state.showMap}>
          <MapChart
            handleClick={(val) => {
              console.log(val);
              this.setState({ showMap: false });
            }}
          />
        </div>
        <div hidden={!this.state.showMap} className="container-fluid">
          <main>
            <div className="page-content m-top">
              <div hidden={true} className="text-center buttonShowMap mt-3">
                <Button hidden={true} onClick={this.showMap}>
                  <i className="pi pi-arrow-circle-left mr-2"></i>Show Map
                </Button>
              </div>
              <div className="analytics">
                <div className="card boxx">
                  <div className="card-head">
                    <h2>
                      {this.props.TotalPrinted ? this.props.TotalPrinted : 0}
                    </h2>
                    <div
                      className="number count-to"
                      data-from="0"
                      data-to="125"
                      data-speed="15"
                      data-fresh-interval="20"
                    ></div>
                    <span className="fa fa-print demap" />
                  </div>
                  <div className="card-progress">
                    <small>QRs Printed</small>
                    <div className="card-indicator">
                      <div
                        className="indicator three"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-head">
                    <h2>{this.props.TotalScans ? this.props.TotalScans : 0}</h2>
                    <div
                      className="number count-to"
                      data-from="0"
                      data-to="257"
                      data-speed="1000"
                      data-fresh-interval="20"
                    ></div>
                    <span className="fa fa-qrcode map" />
                  </div>
                  <div className="card-progress">
                    <small>Total Scans</small>
                    <div className="card-indicator">
                      <div
                        className="indicator two"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-head">
                    <h2>
                      {" "}
                      {this.props.TotalGenuineScans
                        ? this.props.TotalGenuineScans
                        : 0}
                    </h2>
                    <div
                      className="number count-to"
                      data-from="0"
                      data-to="243"
                      data-speed="1000"
                      data-fresh-interval="20"
                    ></div>

                    <span className="fa fa-check issue" />
                  </div>
                  <div className="card-progress">
                    <small>Total Genuine Scans</small>
                    <div className="card-indicator">
                      <div
                        className="indicator one"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-head">
                    <h2>
                      {this.props.TotalFakeScans
                        ? this.props.TotalFakeScans
                        : 0}
                    </h2>
                    <div
                      className="number count-to"
                      data-from="0"
                      data-to="1225"
                      data-speed="1000"
                      data-fresh-interval="20"
                    ></div>

                    <span className="fa fa-times damage" />
                  </div>
                  <div className="card-progress">
                    <small>Total Fake Scans</small>
                    <div className="card-indicator">
                      <div
                        className="indicator four"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <div className="row m-top">
            <div className="col-md-6 boxx">
              <div className="cards">
                <h2>Top Product Scans</h2>
                <Chart
                  type="polarArea"
                  data={{
                    labels: this.props.ProductNames,
                    datasets: [
                      {
                        // label: "Products",
                        data: this.props.ScanCounts,
                        backgroundColor: [
                          "#a86464",
                          "#98d1d1",
                          "#3c4e4b",
                          "#e27c7c",
                          "#d2980d",
                          "#df979e",
                          "#d7658b",
                          "#c80064",
                        ],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 boxx">
              <div className="cards">
                <h2>Genuine Codes Synced</h2>
                <Chart
                  type="bar"
                  data={{
                    labels: this.props.Months,
                    datasets: [
                      {
                        label: "Genuine Codes",
                        data: this.props.MappingChart,
                        backgroundColor: [
                          "#e27c7c",
                          "#a86464",
                          "#6d4b4b",
                          "#503f3f",
                          "#333333",
                          "#3c4e4b",
                          "#466964",
                          "#599e94",
                          "#6cd4c5",
                          "#54bebe",
                          "#76c8c8",
                        ],
                        borderColor: [
                          "#e27c7c",
                          "#a86464",
                          "#6d4b4b",
                          "#503f3f",
                          "#333333",
                          "#3c4e4b",
                          "#466964",
                          "#599e94",
                          "#6cd4c5",
                          "#54bebe",
                          "#76c8c8",
                        ],
                        borderWidth: 1,
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    DashboardList: state.dashboard?.items?.DashboardListData,
    TotalScans: state.dashboard?.items?.DashboardCount?.TotalScans,
    TotalGenuineScans:
      state.dashboard?.items?.DashboardCount?.TotalGenuineScans,
    TotalFakeScans: state.dashboard?.items?.DashboardCount?.TotalFakeScans,
    TotalPrinted: state.dashboard?.items?.DashboardCount?.TotalPrinted,
    DeMappingChart: state.dashboard?.items?.DeMappingList,
    MappingChart: state.dashboard?.items?.MappingList,
    ProductNames: state.dashboard?.items?.ProductNames,
    ScanCounts: state.dashboard?.items?.ScanCounts,
    // FGChart: state.dashboard?.items?.FGList,
    Months: state.dashboard?.items?.MonthName,
    MappingData: state.mapping.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(dashboardActions.fetchData(obj));
    },
    fetchMappingApi: (obj) => {
      dispatch(mappingActions.getAll(obj));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
