import { issueNoService } from '../services';
import { issueNoConstants } from '../constants';
import { alertActions } from '.';
import { history } from '../helpers';

export const issueNoActions = {
    getAll,
    createIssueNo,
    updateIssueNo,
    getFromNumber,
    loadingData,
    onHideShow
};

function getAll(obj) {
    return dispatch => {
        dispatch(request());
        issueNoService.fetchIssueNoData(obj).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: issueNoConstants.GETALL_REQUEST } }
    function success(datas) { return { type: issueNoConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: issueNoConstants.GETALL_FAILURE, error } }
}

function getFromNumber() {
    return dispatch => {
        dispatch(request());

        issueNoService.fetchFromNumbersData().then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: issueNoConstants.GETALL_FROMNO_REQUEST } }
    function success(datas) { return { type: issueNoConstants.GETALL_FROMNO_SUCCESS, datas } }
    function failure(error) { return { type: issueNoConstants.GETALL_FROMNO_FAILURE, error } }
}

function createIssueNo(data) {
    return dispatch => {
        dispatch(request(data));

        issueNoService.createIssueNo(data).then(
            response => {
                //history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(success(response.ResponseData));
                        dispatch(onHideShow(false));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: issueNoConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: issueNoConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: issueNoConstants.CREATE_FAILURE, error } }
};

function updateIssueNo(data) {
    return dispatch => {
        dispatch(request(data));

        issueNoService.createIssueNo(data).then(
            response => {
                dispatch(success(data));
                //history.push('/');                
                if (response && response.ResponseCode) {
                    response.ResponseCode == "200" ? dispatch(alertActions.success(response.ResponseMessage))
                        : response.ResponseCode == "400" ? dispatch(alertActions.warning(response.ResponseMessage))
                        : dispatch(alertActions.error(response.ResponseMessage))
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: issueNoConstants.UPDATE_REQUEST, data } }
    function success(data) { return { type: issueNoConstants.UPDATE_SUCCESS, data } }
    function failure(error) { return { type: issueNoConstants.UPDATE_FAILURE, error } }
};

function loadingData(isbool) {
    return {
        type: issueNoConstants.LOADING_REQUEST, isbool
    }
}

function onHideShow(isbool) {
    return {
        type: issueNoConstants.DIALOG_REQUEST, isbool
    }
}


