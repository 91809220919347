////to get verify product model

export default class VerifyProduct {
  VerificationMode = "";
  QrData = "";
  VerificationType = 0;
  CompanyPrefix = "";
  Userlatitude = 0;
  Userlongitude = 0;
  UserCity = "";
  UserState = "";
  UserCountry = "";
  UserZip = "";
  UserAddress = "";
  ScratchCode = "";
}
