import { demappingService, mappingService } from '../services';
import { demappingConstants, mappingConstants } from '../constants';
import { alertActions } from '.';
import { history } from '../helpers';

export const demappingActions = {
    getAll,
    createDemapping,
    getFromNumber,
    onHideShow,
    loadingData
};

function getAll(obj) {
    return dispatch => {
        dispatch(request());
        mappingService.fetchMappingData(obj).then(response => {
            dispatch(loadingData(false));
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(loadingData(false));
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: demappingConstants.GETALL_REQUEST } }
    function success(datas) { return { type: demappingConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: demappingConstants.GETALL_FAILURE, error } }
}

function createDemapping(data) {
    return dispatch => {
        dispatch(request(data));
        demappingService.createDemapping(data).then(
            response => {
                dispatch(success(response.ResponseData));
                //history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode === "200") {
                        dispatch(onHideShow(false));
                        dispatch(alertActions.success(response.ResponseMessage));
                    } else if (response.ResponseCode === "400") {
                        dispatch(alertActions.warning(response.ResponseMessage))
                    } else {
                        dispatch(alertActions.error(response.ResponseMessage))
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: demappingConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: demappingConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: demappingConstants.CREATE_FAILURE, error } }
};

function getFromNumber(obj) {
    return dispatch => {
        dispatch(request());

        demappingService.fetchFromNumbersData(obj).then(response => {
            dispatch(success(response.ResponseData))
        },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request() { return { type: demappingConstants.GETALL_REQUEST } }
    function success(datas) { return { type: demappingConstants.GETALL_SUCCESS, datas } }
    function failure(error) { return { type: demappingConstants.GETALL_FAILURE, error } }
}
function onHideShow(isbool) {
    return {
        type: demappingConstants.DIALOG_REQUEST, isbool
    }
}

function loadingData(isbool) {
    return {
        type: demappingConstants.LOADING_REQUEST, isbool
    }
}





