import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { Button } from "primereact/button";
import { connect } from "react-redux";
import { Dialog } from "primereact/dialog";
import VerifyProduct from "../models/VerifyProduct";
import { productAuthenticationActions } from "../actions";
import { handleResponse } from "../helpers";

import sucessIcon from "./../assets/images/sucess-code.png";
import fakeIcon from "./../assets/images/wrong-code.png";
import { Rating } from "primereact/rating";

import InstaIcon from "./../assets/images/instagram.png";
//import LinkedinIcon from './../assets/images/linkedIn.png';
import OtherLinkIcon from "./../assets/images/link.png";
import Logo from "./../assets/images/login_logo.png";

import PinterestIcon from "./../assets/images/pinterest.png";
import ReactPlayer from "react-player";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

export class ProductAuthentication extends Component {
  constructor(props) {
    super(props);
    this.clsVerifyProduct = new VerifyProduct();
    this.state = {
      formIsValid: false,
      IsLocation: false,
      ratingBox: false,
      shareBox: false,
      VerifyProduct: {},
      rating: null,
      showDialog: false,
      VerificationMode: "Web",
      QrData: window.location.href,
      VerificationType: 2,
      VerificationId: 0,
      displayBasic: false,
      UserId: "",
      SeqNo: "",
      Name: "",
      email: "",
      MobileNo: "",
      UserFeedback: "",
      showVideo: true,
      photoIndex: 0,
      isOpen: false,
      socialLinks: "",
      formError: {
        Name: null,
        email: null,
        MobileNo: null,
        UserFeedback: null,
      },
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hideFunc = this.hideFunc.bind(this);
    this.onHide = this.onHide.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.updateRating = this.updateRating.bind(this);
    this.ImgModal = this.ImgModal.bind(this);
    this.reverseGeocodeLatLngAndVerifyProduct =
      this.reverseGeocodeLatLngAndVerifyProduct.bind(this);
    const urlParams = new URLSearchParams(window.location.search);
    const parmUid = urlParams.get("uid");
    //const curl = window.location.href;
    this.clsVerifyProduct.QrData = this.state.QrData;
    this.clsVerifyProduct.VerificationType = this.state.VerificationType;
    this.findMe();
  }

  //get reverse geo code
  reverseGeoCode(lat, long) {
    // tslint:disable-next-line:max-line-length
    const requestOptions = {
      method: "GET",
    };
    return fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`,
      requestOptions
    ).then(handleResponse);
    //   return fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}&zoom=18&addressdetails=1`);
  }

  //to trigger api on page call
  componentDidMount() {
    const caches = window.caches;
    if (window.caches) {
      window.caches.keys().then(async function (names) {
        await Promise.all(names.map((name) => window.caches.delete(name)));
      });
    }

    // const urlParams = new URLSearchParams(window.location.search);
    // const parmUid = urlParams.get('uid');

    // const curl = window.location.href;

    // this.clsVerifyProduct.verificationMode = this.state.verificationMode;
    // this.clsVerifyProduct.qrData = this.state.qrData;
    // this.clsVerifyProduct.verificationType = this.state.verificationType;
    // this.clsVerifyProduct.UID = parmUid;

    // setTimeout(() => {
    //     // this.findMe();
    // }, 1000);
    // this.findMe();
  }

  // get verify product list data
  GetVerifyProduct(productAuthReq) {
    this.setState({
      IsLocation: false,
    });
    productAuthReq.QrData = productAuthReq.QrData.split("pa?uid=")[1];
    this.props.verifyProduct(productAuthReq);
  }
  //to hide function
  hideFunc() {
    this.setState({
      displayBasic: false,
    });
  }

  //to validate feedback form
  validateFeedback(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    switch (name) {
      case "Name":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.Name = "Please enter your name.";
        } else if (value.length >= 1) {
          if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
            IsValid = false;
            errors.Name = "Please enter only alphabet.";
          } else errors.Name = "";
        } else errors.Name = "";
        break;
      case "email":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.email = "Please enter your email-id.";
        } else if (value && value.length > 1) {
          if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
            IsValid = false;
            errors.email = "Please enter valid email-id.";
          } else errors.email = "";
        } else errors.email = "";
        break;
      case "MobileNo":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.MobileNo = "Please enter your mobile no.";
        } else if (value && value.length > 1) {
          if (!value.match(/^[6-9]\d{9}$/)) {
            IsValid = false;
            errors.MobileNo = "Please enter valid mobile no.";
          } else errors.MobileNo = null;
        } else errors.MobileNo = "";
        break;
      case "UserFeedback":
        if (!value || value.length < 1) {
          IsValid = false;
          errors.UserFeedback = "Please enter your feedback.";
        } else errors.UserFeedback = "";
        break;

      default:
        break;
    }

    return IsValid;

    // let errors = this.state.formError;
    // let formFeedIsValid = true;

    // switch (name) {
    //     case 'Name':
    //         if (value === null || value.length < 1) {
    //             formFeedIsValid = false;
    //             errors.Name = "Please enter your name.";
    //         } else if (value.length >= 1) {
    //             if (!value.match(/^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/)) {
    //                 formFeedIsValid = false;
    //                 errors.Name = "Please enter only alphabet.";
    //             } else errors.Name = null
    //         } else errors.Name = null
    //         break;
    //     case 'email':
    //         if (value === null || value === '' || value.length >= 1) {
    //             /* eslint-disable */
    //             if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
    //                 formFeedIsValid = false;
    //                 errors.email = "Please enter valid email-id.";
    //             } else errors.email = null
    //             /* eslint-enable */
    //         } else errors.email = null
    //         break;
    //     case 'MobileNo':
    //         if (value === null || value.length < 1) {
    //             formFeedIsValid = false;
    //             errors.MobileNo = "Please enter your mobile no.";
    //         }
    //         else if (value.length >= 1) {
    //             if (!value.match(/^[6-9]\d{9}$/)) {
    //                 formFeedIsValid = false;
    //                 errors.MobileNo = "Please enter valid mobile no.";
    //             } else errors.MobileNo = null
    //         } else errors.MobileNo = null
    //         break;
    //     case 'UserFeedback':
    //         if (value === null || value.length < 1) {
    //             formFeedIsValid = false;
    //             errors.UserFeedback = "Please enter your feedback.";
    //         } else errors.UserFeedback = null
    //         break;
    //     default:
    //         break;
    // }

    // return formFeedIsValid;
  }

  ImgModal() {
    var modal = document.getElementById("myModal");

    // Get the image and insert it inside the modal - use its "alt" text as a caption
    var img = document.getElementById("myImg");
    var modalImg = document.getElementById("img01");
    // var captionText = document.getElementById("caption");
    img.onclick = function () {
      modal.style.display = "block";
      modalImg = document.getElementById("img01");
      // captionText.innerHTML = this.alt;
    };

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
      modal.style.display = "none";
    };
  }

  submitForm(event) {
    // const { name, value } = event.target;
    event.preventDefault();

    let isfeedfrmvalid = true;
    let checkerror = this.state.formError;
    Object.entries(checkerror).map(([key, value]) => {
      const val = this.state[key];
      let valid = this.validateFeedback(val, key);
      if (!valid) {
        this.setState({ checkerror: isfeedfrmvalid });
        isfeedfrmvalid = false;
      }

      return isfeedfrmvalid;
    });

    const feedbackData = {
      Name: this.state.Name,
      email: this.state.email,
      MobileNo: this.state.MobileNo,
      UserFeedback: this.state.UserFeedback,
      VerificationId: this.props.ProductAuthenticationData.VerificationId,
    };

    if (isfeedfrmvalid) {
      this.props.UpdateFeedback(feedbackData);
    }

    this.setState({ [feedbackData]: "" });
  }

  formReset() {
    this.setState({
      Name: "",
      email: "",
      MobileNo: "",
      UserFeedback: "",
      formError: {
        Name: null,
        email: null,
        MobileNo: null,
        UserFeedback: null,
      },
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  updateRating(productId, verificationId, event) {
    this.setState({ rating: event.value });

    var data = {
      ProductId: productId,
      ProductRating: event.value,
      SeqNo: event.value,
      VerificationId: verificationId,
    };

    if (productId) {
      this.props.updateRating(data);
    } else {
    }
  }

  handleChange(event) {
    const { name, value } = event.target;
    let errors = this.state.scratchError;

    this.validateFeedback(value, name);
    this.setState({ errors, [name]: value });
  }
  onHide() {
    this.props.onHideShow(false);
  }

  openDialog() {
    this.props.onHideShow(true);
  }

  //to find  location
  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.showPosition(position);
        },
        (denied) => {
          this.GetVerifyProduct(this.clsVerifyProduct);
        },
        { enableHighAccuracy: true, maximumAge: 10000 }
      );
    } else {
      this.GetVerifyProduct(this.clsVerifyProduct);
    }
  }
  //to get exact longitude and latitude
  showPosition(position) {
    this.clsVerifyProduct.Userlongitude = position.coords.longitude;
    this.clsVerifyProduct.Userlatitude = position.coords.latitude;
    this.reverseGeocodeLatLngAndVerifyProduct();
  }
  //to get location
  reverseGeocodeLatLngAndVerifyProduct() {
    // tslint:disable-next-line: max-line-length
    this.reverseGeoCode(
      this.clsVerifyProduct.Userlatitude,
      this.clsVerifyProduct.Userlongitude
    ).then(
      (response) => {
        const res = response;
        this.clsVerifyProduct.UserCity = res.address.state_district;
        this.clsVerifyProduct.UserState = res.address.state;
        this.clsVerifyProduct.UserCountry = res.address.country;
        this.clsVerifyProduct.UserZip = res.address.postcode;
        this.clsVerifyProduct.UserAddress = res.display_name;
        this.setState({
          Location: res.display_name,
        });
        this.GetVerifyProduct(this.clsVerifyProduct);
      },
      (error) => {
        this.GetVerifyProduct(this.clsVerifyProduct);
      }
    );
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    let links = null;
    links =
      this.props?.ProductAuthenticationData?.SocialLinks &&
      JSON.parse(this.props?.ProductAuthenticationData?.SocialLinks);
    let share = null;
    return (
      <section className="">
        <div className="pa-outer row wapperbgColor">
          {this.props.ProductAuthenticationData != null &&
          this.props.ProductAuthenticationData.ResponseCode == "200" ? (
            <div className="container" style={{ zIndex: 0 }}>
              {/* <Dialog
                visible={this.state.showVideo}
                onHide={() => this.setState({ showVideo: false })}
              >
                <ReactPlayer
                  className="youtube-video"
                  url={this.props.ProductAuthenticationData.VideoFile}
                  playing={true}
                  controls={true}
                />
              </Dialog> */}
              {(this.props.ProductAuthenticationData.GenuineAudio != null) ===
              true ? (
                <audio
                  controls
                  autoPlay
                  src={this.props.ProductAuthenticationData.GenuineAudio}
                  id="pm3paly"
                  hidden
                />
              ) : (
                ""
              )}

              <div className="success-message">
                <img src={sucessIcon} />
                <h2>
                  {this.props.ProductAuthenticationData.ResponseMessage}
                </h2>
              </div>
              <div
                id="testId"
                className="text-center image-wraper product-auth-img-box col-md-12 mt-2"
              >
                <br />
                <div className="logo-wraper">
                  <div className="logo-c" onClick={this.ImgModal}>
                    <img
                      id="myImg"
                      src={this.props.ProductAuthenticationData.CompanyLogo}
                      alt="Trolltunga, Norway"
                      width="300"
                      height="200"
                    />

                    <div id="myModal" class="modal" style={{ display: "none" }}>
                      <span class="close mt-3 mr-3">&times;</span>
                      <img class="modal-content" src={Logo} id="img01" />
                      <div id="caption"></div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <img
                    className="productimg"
                    src={this.props.ProductAuthenticationData.ProductImage}
                  />
                </div>
              </div>
              <div className="product-detail mt-3">
                <h3>{this.props.ProductAuthenticationData.ProductName}</h3>

                {this.props.ProductAuthenticationData.IsRating != null &&
                this.props.ProductAuthenticationData.IsRating != "0" ? (
                  <span
                    onClick={() =>
                      this.setState({ ratingBox: !this.state.ratingBox })
                    }
                  >
                    <i className="fa fa-star" aria-hidden="true"></i>Rating
                    &nbsp;
                    {this.props.Rating && this.props.Rating.ProductRating
                      ? this.props.Rating.ProductRating
                      : this.props.ProductAuthenticationData.ProductRating}
                    (
                    {this.props.Rating && this.props.Rating.TotalRating
                      ? this.props.Rating.TotalRating
                      : this.props.ProductAuthenticationData.TotalRating == null
                      ? 0
                      : this.props.ProductAuthenticationData.TotalRating}
                    )
                  </span>
                ) : null}

                {this.state.ratingBox ? (
                  <div className="rating-wraper">
                    {this.props.ProductAuthenticationData.isRated == 0 ? (
                      <Rating
                        cancel={false}
                        value={this.state.rating}
                        onChange={(e) =>
                          this.updateRating(
                            this.props.ProductAuthenticationData.ProductId,
                            this.props.ProductAuthenticationData.VerificationId,
                            e
                          )
                        }
                      />
                    ) : (
                      <Rating
                        cancel={false}
                        readonly
                        value={
                          this.props.ProductAuthenticationData.ProductRating
                        }
                        onChange={(e) =>
                          this.updateRating(
                            this.props.ProductAuthenticationData.ProductId,
                            this.props.ProductAuthenticationData.VerificationId,
                            e
                          )
                        }
                      />
                    )}
                  </div>
                ) : null}

                {this.props.ProductAuthenticationData.IsFeedback != null &&
                this.props.ProductAuthenticationData.IsFeedback != "0" ? (
                  <span onClick={() => this.openDialog()}>
                    <i className="fa fa-comments-o" aria-hidden="true"></i>{" "}
                    Feedback
                  </span>
                ) : null}

                {this.props.ProductAuthenticationData.IsShare != null &&
                this.props.ProductAuthenticationData.IsShare != "0" ? (
                  <span
                    onClick={() =>
                      this.setState({ shareBox: !this.state.shareBox })
                    }
                  >
                    <i className="fa fa-share" aria-hidden="true"></i>
                    Share
                  </span>
                ) : null}

                {this.state.shareBox ? (
                  <div className="share-wraper">
                    <div className="Demo__some-network">
                      <FacebookShareButton
                        url={this.props.ProductAuthenticationData.ShareURL}
                        quote={this.props.ProductAuthenticationData.ShareURL}
                        className="Demo__some-network__share-button"
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                    </div>
                    <div className="Demo__some-network">
                      <TwitterShareButton
                        url={this.props.ProductAuthenticationData.ShareURL}
                        quote={this.props.ProductAuthenticationData.ShareURL}
                        className="Demo__some-network__share-button"
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                    </div>
                    <div className="Demo__some-network">
                      <WhatsappShareButton
                        url={this.props.ProductAuthenticationData.ShareURL}
                        quote={this.props.ProductAuthenticationData.ShareURL}
                        className="Demo__some-network__share-button"
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>
                    </div>
                    <div className="Demo__some-network">
                      <LinkedinShareButton
                        url={this.props.ProductAuthenticationData.ShareURL}
                        quote={this.props.ProductAuthenticationData.ShareURL}
                        className="Demo__some-network__share-button"
                      >
                        <LinkedinIcon size={32} round />
                      </LinkedinShareButton>
                    </div>
                    <div className="Demo__some-network">
                      <EmailShareButton
                        url={this.props.ProductAuthenticationData.ShareURL}
                        quote={this.props.ProductAuthenticationData.ShareURL}
                        className="Demo__some-network__share-button"
                      >
                        <EmailIcon size={32} round />
                      </EmailShareButton>
                    </div>
                  </div>
                ) : null}
                {this.props.ProductAuthenticationData != null &&
                this.props.ProductAuthenticationData.SocialLinks != null ? (
                  <span
                    onClick={() =>
                      this.setState({ socialLinks: !this.state.socialLinks })
                    }
                  >
                    <i class="fa fa-link" aria-hidden="true"></i>
                    Social Links
                  </span>
                ) : null}

                {this.state.socialLinks ? (
                  <div className="share-wraper">
                    <span>
                      {this.props.ProductAuthenticationData != null &&
                      this.props.ProductAuthenticationData.SocialLinks !=
                        null ? (
                        <>
                          <span className="col-md-6 pb-3">
                            {Object.keys(links).map((x) => {
                              switch (x) {
                                case "Facebook": {
                                  return (
                                    <span className="mr-2">
                                      <a href={links[x]} target="_blank">
                                        <FacebookIcon size={27} round />
                                      </a>
                                    </span>
                                  );
                                }
                                case "Instagram": {
                                  return (
                                    <span className="mr-2">
                                      <a href={links[x]} target="_blank">
                                        <img
                                          width="27px"
                                          height="27px"
                                          src={InstaIcon}
                                        ></img>
                                      </a>
                                    </span>
                                  );
                                }
                                case "LinkedIn": {
                                  return (
                                    <span className="mr-2">
                                      <a href={links[x]} target="_blank">
                                        <img
                                          width="27px"
                                          height="27px"
                                          src={LinkedinIcon}
                                        ></img>
                                      </a>
                                    </span>
                                  );
                                }
                                case "Twitter": {
                                  return (
                                    <span className="mr-2">
                                      <a href={links[x]} target="_blank">
                                        <img
                                          width="27px"
                                          height="27px"
                                          src={TwitterIcon}
                                        ></img>
                                      </a>
                                    </span>
                                  );
                                }
                                case "Pinterest": {
                                  return (
                                    <span className="mr-2">
                                      <a href={links[x]} target="_blank">
                                        <img
                                          width="27px"
                                          height="27px"
                                          src={PinterestIcon}
                                        ></img>
                                      </a>
                                    </span>
                                  );
                                }
                                default: {
                                  return (
                                    <span className="mr-2">
                                      <a href={links[x]} target="_blank">
                                        <img
                                          width="27px"
                                          height="27px"
                                          src={OtherLinkIcon}
                                        ></img>
                                      </a>
                                    </span>
                                  );
                                }
                              }
                            })}
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="row mt-4">
                <div className="auth_blockproduct">
                  <table className="authTableProduct">
                    {this.props.ProductAuthenticationData
                      .ProductSpecification != null
                      ? this.props.ProductAuthenticationData.ProductSpecification.map(
                          (x, i) => {
                            if (x.Key == "Website") {
                              return (
                                <tbody>
                                  <tr>
                                    <td className="authProdutname">
                                      <p>{x.Key}</p>
                                    </td>

                                    <td>
                                      <p>
                                        <a
                                          style={{ color: "red" }}
                                          href={x.Value}
                                          target="_blank"
                                        >
                                          {" "}
                                          {x.Value}
                                        </a>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            } else {
                              return (
                                <tbody>
                                  <tr>
                                    <td className="authProdutname">
                                      <p>{x.Key}</p>
                                    </td>

                                    <td>
                                      <p>{x.Value}</p>
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            }
                          }
                        )
                      : null}
                  </table>
                </div>
              </div>
            </div>
          ) : (
            // <div className="container-fluid p-0">{
            //     (this.props.ProductAuthenticationData != null && this.props.ProductAuthenticationData.FakeAudio) === true
            //         ?
            //         <audio controls autoPlay src={this.props.ProductAuthenticationData.FakeAudio} id="pm3paly" hidden />
            //         : ""}

            //     <div className="success-message">
            //         {
            //             this.props.ProductAuthenticationData != null ?
            //                 <div className="text-center p-3"> <img src={fakeIcon} className="mb-3" />
            //                     <h2 className="mb-4">{this.props.ProductAuthenticationData.ResponseMessage}</h2>
            //                 </div>
            //                 : ""
            //         }
            //     </div>
            // </div>

            <div className="container-fluid p-0">
              {(this.props.ProductAuthenticationData != null &&
                this.props.ProductAuthenticationData.FakeAudio) === true ? (
                <audio
                  controls
                  autoPlay
                  src={this.props.ProductAuthenticationData.FakeAudio}
                  id="pm3paly"
                  hidden
                />
              ) : (
                ""
              )}

              <div className="success-message box">
                {this.props.ProductAuthenticationData != null ? (
                  <div className="text-center p-3">
                    {" "}
                    <img src={fakeIcon} className="mb-3" />
                    <h2 className="mb-4">
                      {this.props.ProductAuthenticationData.ResponseMessage}
                    </h2>
                  </div>
                ) : (
                  ""
                )}

                <button
                  type="button"
                  className="btn btn-primary btn-lg"
                  onClick={() => this.openDialog()}
                >
                  Feedback
                </button>
              </div>
            </div>
          )}
          ;
          <Dialog
            header="Feedback Form"
            modal
            dismissableMask={true}
            visible={this.props.ShowDialogFeedback}
            className="popup"
            onHide={this.onHide}
          >
            <form onSubmit={this.submitForm}>
              <div className="row">
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="Name"
                    value={this.state.Name}
                    className="fullwidth"
                    label="Name*"
                  />
                  {this.state.formError.Name !== "" ? (
                    <div className="error">{this.state.formError.Name}</div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="email"
                    value={this.state.email}
                    className="fullwidth"
                    label="Email*"
                  />
                  {this.state.formError.email !== "" ? (
                    <div className="error">{this.state.formError.email}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="MobileNo"
                    value={this.state.MobileNo}
                    className="fullwidth"
                    label="Mobile number*"
                  />
                  {this.state.formError.MobileNo !== "" ? (
                    <div className="error">{this.state.formError.MobileNo}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-6 pt-2">
                  <TextField
                    id="standard-basic"
                    size="small"
                    onChange={this.handleChange}
                    variant="outlined"
                    name="UserFeedback"
                    value={this.state.UserFeedback}
                    className="fullwidth"
                    label="Feedback*"
                  />
                  {this.state.formError.UserFeedback !== "" ? (
                    <div className="error">
                      {this.state.formError.UserFeedback}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-12 mt-3 text-center">
                  <Button label="Submit" icon="pi pi-check" />
                </div>
              </div>
            </form>
          </Dialog>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Rating: state.productAuthentication.rating,
    ProductAuthenticationData:
      (state.productAuthentication.items &&
        state.productAuthentication.items.length) > 0
        ? state.productAuthentication.items[0]
        : null,
    ShowDialogFeedback: state.productAuthentication.showDialogFeedback,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyProduct: (productAuth) =>
      dispatch(productAuthenticationActions.verifyProduct(productAuth)),
    UpdateFeedback: (feedback) =>
      dispatch(productAuthenticationActions.UpdateFeedback(feedback)),
    updateRating: (ratingData) =>
      dispatch(productAuthenticationActions.updateRating(ratingData)),
    onHideShow: (isbool) =>
      dispatch(productAuthenticationActions.onHideShow(isbool)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductAuthentication);
