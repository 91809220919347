import { changePasswordService } from '../services';
import { changePasswordConstants } from '../constants';
import { alertActions } from '.';

export const changePasswordActions = {
    changePassword,
    forgotPassword
};

function changePassword(data) {
    return dispatch => {
        dispatch(request(data));

        changePasswordService.changePassword(data).then(
            response => {
                if (response && response.ResponseCode) {
                    if (response.ResponseCode == "200") {
                        dispatch(alertActions.success(response.ResponseMessage));

                        setTimeout(() => {
                            sessionStorage.clear();
                            window.location = '/login';
                        }, 2000);
                    }
                    else {
                        dispatch(alertActions.error(response.ResponseMessage));
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: changePasswordConstants.CREATE_REQUEST, data } }
    function success(data) { return { type: changePasswordConstants.CREATE_SUCCESS, data } }
    function failure(error) { return { type: changePasswordConstants.CREATE_FAILURE, error } }
};

function forgotPassword(data) {
    return dispatch => {
        dispatch(request(data));

        changePasswordService.forgotPassword(data).then(
            response => {
                // history.push('/');
                if (response && response.ResponseCode) {
                    if (response.ResponseCode == "200") {
                        dispatch(alertActions.success(response.ResponseMessage));
                    }
                    else {
                        dispatch(alertActions.error(response.ResponseMessage));
                    }
                }
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(error.toString()));
            }
        );
    };

    function request(data) { return { type: changePasswordConstants.CREATE_FORGOT_REQUEST, data } }
    function success(data) { return { type: changePasswordConstants.CREATE_FORGOT_SUCCESS, data } }
    function failure(error) { return { type: changePasswordConstants.CREATE_FORGOT_FAILURE, error } }
};


