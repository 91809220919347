export const productConstants = {
  CREATE_REQUEST: "PRODUCTS_CREATE_REQUEST",
  CREATE_SUCCESS: "PRODUCTS_CREATE_SUCCESS",
  CREATE_FAILURE: "PRODUCTS_CREATE_FAILURE",

  UPDATE_REQUEST: "PRODUCTS_UPDATE_REQUEST",
  UPDATE_SUCCESS: "PRODUCTS_UPDATE_SUCCESS",
  UPDATE_FAILURE: "PRODUCTS_UPDATE_FAILURE",

  GETALL_REQUEST: "PRODUCTS_GETALL_REQUEST",
  GETALL_SUCCESS: "PRODUCTS_GETALL_SUCCESS",
  GETALL_FAILURE: "PRODUCTS_GETALL_FAILURE",

  GETALL_RATING_REQUEST: "PRODUCTS_GETALL_RATING_REQUEST",
  GETALL_RATING_SUCCESS: "PRODUCTS_GETALL_RATING_SUCCESS",
  GETALL_RATING_FAILURE: "PRODUCTS_GETALL_RATING_FAILURE",

  EXPORTALL_RATING_REQUEST: "PRODUCTS_EXPORTALL_RATING_REQUEST",
  EXPORTALL_RATING_SUCCESS: "PRODUCTS_EXPORTALL_RATING_SUCCESS",
  EXPORTALL_RATING_FAILURE: "PRODUCTS_EXPORTALL_RATING_FAILURE",

  DELETE_REQUEST: "PRODUCTS_DELETE_REQUEST",
  DELETE_SUCCESS: "PRODUCTS_DELETE_SUCCESS",
  DELETE_FAILURE: "PRODUCTS_DELETE_FAILURE",

  ISACTIVE_REQUEST: "PRODUCTS_ISACTIVE_REQUEST",
  ISACTIVE_SUCCESS: "PRODUCTS_ISACTIVE_SUCCESS",
  ISACTIVE_FAILURE: "PRODUCTS_ISACTIVE_FAILURE",

  GETPRDCTDDL_REQUEST: "PRODUCTS_GETPRDCTDDL_REQUEST",
  GETPRDCTDDL_SUCCESS: "PRODUCTS_GETPRDCTDDL_SUCCESS",
  GETPRDCTDDL_FAILURE: "PRODUCTS_GETPRDCTDDL_FAILURE",

  DIALOG_REQUEST: "DIALOG_REQUEST",

  LOADING_REQUEST: "LOADING_REQUEST",

  DIALOG_ISACTIVE_REQUEST: "DIALOG_ISACTIVE_REQUEST",
};
