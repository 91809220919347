import { authHeader, handleResponse } from '../helpers';
export const userService = {
    createUser,
    updateUser,
    fetchUserData,
    inActiveRow
};

function createUser(data) {
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/CreateUser`, requestOptions)
        .then(handleResponse);
}

function updateUser(data) {

    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/UpdateUser`, requestOptions)
        .then(handleResponse);
}

function fetchUserData(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };

    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/GetUsers`, requestOptions)
        .then(handleResponse);
}

function inActiveRow(userId, isActive) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(`${process.env.REACT_APP_API_URL}UserMaster/InActiveRow/${userId}/${isActive}`, requestOptions)
        .then(handleResponse);
}



