import React, { Component } from 'react'
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Dashboard from './../views/Dashboard';
import CompanyMaster from './../components/masters/companyMaster';
import ProductMaster from './../components/masters/productMaster';
import roleMaster from './../components/masters/roleMaster';
import SqlEditor from '../components/masters/SqlEditor';
import Profile from './../views/Profile';
import IssueNumber from '../components/mapping/IssueNumber';
import Mapping from './../components/transactions/Mapping';
import DeMapping from './../components/transactions/DeMapping';
import Damage from './../components/transactions/Damage';
import UserMaster from './../components/masters/userMaster';
import VerificationLog from './../components/reports/VerificationLog';
import MappingRpt from './../components/reports/MappingRpt';
import DemappingRpt from './../components/reports/DemappingRpt';
import DamageRpt from '../components/reports/DamageRpt';
import FeedbackRpt from './../components/reports/FeedbackRpt';
import ProductConfiguration from '../components/masters/ProductConfiguration';
import ProductRatingReport from '../components/reports/ProductRatingReport';

export default class Template extends Component {
    render() {
        return (
            <div className="container-fluid ">
                <Header />
                <div className="row">
                    <div className="col-md-2 sidebar-bg side_menu"><Sidebar /></div>
                    <div className="col-md-10 body-part ">
                        <Router>
                            <Switch>
                                <Route exact path="/dashboard" component={Dashboard} />
                                <Route exact path="/profile" component={Profile} />
                                <Route exact path="/masters/company-master" component={CompanyMaster} />
                                <Route exact path="/masters/product-master" component={ProductMaster} />
                                <Route exact path="/masters/role-master" component={roleMaster} />
                                <Route exact path="/masters/user-master" component={UserMaster} />
                                <Route exact path="/masters/product-configuration" component={ProductConfiguration} />
                                <Route exact path="/masters/sql-editor" component={SqlEditor} />
                                <Route exact path="/activation/issue-number" component={IssueNumber} />
                                <Route exact path="/transactions/damage" component={Damage} />
                                <Route exact path="/transactions/mapping" component={Mapping} />
                                <Route exact path="/transactions/de-mapping" component={DeMapping} />
                                <Route exact path="/reports/verification-log" component={VerificationLog} />
                                <Route exact path="/reports/product-rating-report" component={ProductRatingReport} />
                                <Route exact path="/reports/mapping-report" component={MappingRpt} />
                                <Route exact path="/reports/Demapping-report" component={DemappingRpt} />
                                <Route exact path="/reports/Damage-report" component={DamageRpt} />
                                <Route exact path="/reports/feedback-report" component={FeedbackRpt} />
                                <Route path="*" component={Dashboard} />
                            </Switch>
                        </Router>
                    </div>
                </div>
                <Footer />

            </div>
        )
    }
}
